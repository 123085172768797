<template>
  <div class="content">
    <g-loading :loading="loading" />
    <select v-model="user_id">
      <option value="user1">user1</option>
      <option value="user2">user2</option>
      <option value="user3">user3</option>
    </select>
    <br/>
    <!-- 文字消息 -->
    <input v-model="text" style="border: 1px solid #ccc;"/>
    <button @click="send_text_message(conversation_id, text)">发送消息</button>
    <br/>
    <!-- 图片消息 -->
    <van-uploader
      :after-read="after_read" />
    <br/>
    <button @click="get_history_message(conversation_id)">获取历史消息</button>
    <br/>
    <button @click="delete_conversation('C2Cuser1')">删除会话1</button>
    <br/>
    <button @click="delete_conversation('C2Cuser2')">删除会话2</button>
    <br/>
    <button @click="delete_conversation('C2Cuser3')">删除会话3</button>
    <br/>
    <button @click="resend_message(message_list[message_list.length - 1])">重新发送</button>
    <br/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  // get_onversation_list,
  delete_conversation,
  get_history_message,
  get_conversation,
  user_conversation_id,
  set_message_read,
  resend_message,
  send_text_message,
  send_img_message,
} from './index'
import { arr_obj } from '../utils/format'

export default {
  data() {
    return {
      loading: false,
      user_id: 'user2',
      text: '',
    }
  },
  computed: {
    ...mapState('im', {
      conversation_list: state => state.conversation_list,
      all_conversation_data: state => state.all_conversation_data,
    }),
    conversation_id () {
      return user_conversation_id(this.user_id)
    },
    conversation_obj () {
      return arr_obj(this.conversation_list, {value: 'conversationID'})
    },
    // 有无历史消息
    is_completed () {
      const data = this.all_conversation_data[this.conversation_id]
      if (data) return data.is_completed
      return false
    },
    // 消息列表
    message_list () {
      const data = this.all_conversation_data[this.conversation_id]
      if (data) return data.message_list
      return []
    },
    // 会话资料
    profile () {
      return this.conversation_obj[this.conversation_id] || {}
    },
    // 会话状态
    status () {
      return this.profile._state
    },
  },
  watch: {
    conversation_list (val) {
      console.log('会话列表', val)
    },
    all_conversation_data (val) {
      console.log('总消息', val)
    },
    message_list (val) {
      // 设置消息已读
      set_message_read(this.conversation_id)
      console.log('当前会话消息', val)
    },
    conversation_id () {
      this.init()
    }
  },
  components: {
  },
  created () {
    // 设置消息已读
    set_message_read(this.conversation_id)
    // 会话列表
    // get_onversation_list()
    this.init()
  },
  methods: {
    delete_conversation,
    send_text_message,
    resend_message,
    get_history_message,
    async init () {
      this.loading = true
      try {
        // 获取会话
        await get_conversation(this.conversation_id)
      } finally {
        this.loading = false
      }
    },
    async after_read (file) {
      console.log(file)
      this.loading = true
      try {
        // 发送图片
        await send_img_message(this.conversation_id, file.file)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<!-- Add "scoped " attribute to limit CSS to this component only -->
<style scoped>
button {
  margin: 5px 10px;
}
</style>
